<script setup lang="ts">
const props = defineProps<{
  show: boolean;
  yesLabel?: string;
  noLabel?: string;
}>();

const emit = defineEmits(['yes', 'no'])

const onYes = () => emit('yes')
const onNo = () => emit('no')
</script>
<template>
  <div v-if="show" class="fixed inset-0 z-50 flex items-center justify-center">
    <div class="absolute inset-0 bg-black opacity-50"></div>
    <div class="relative bg-white rounded-lg p-6 max-w-sm w-full">
      <div class="mb-4">
        <slot></slot>
      </div>
      <div class="flex justify-end space-x-2">
        <button @click="onNo" class="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300">
          {{ noLabel }}
        </button>
        <button @click="onYes" class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
          {{ yesLabel }}
        </button>
      </div>
    </div>
  </div>
</template>

