<script lang="ts" setup>
import {
  Alert as AAlert,
  Button as AButton,
  Form as AForm,
  Input as AInput,
  InputNumber as AInputNumber,
  Radio as ARadio,
  Select as ASelect,
  Spin as ASpin,
  Switch as ASwitch,
} from 'ant-design-vue'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { getRealm } from '~/realm/setup'
import { useAlertStore } from '~/store/alert'
import { useAclStore } from '~/store/acl'

const props = defineProps({
  defaultUsername: {
    default: '',
    type: String,
  },
  defaultPassword: {
    default: '',
    type: String,
  },
  title: {
    default: 'Login',
    type: String,
  },
})
const AInputGroup = AInput.Group
const ARadioGroup = ARadio.Group
const ASelectOption = ASelect.Option
const AFormItem = AForm.Item
const ARadioButton = ARadio.Button

const email = ref('')
const loading = ref(false)
const sent = ref(false)
const $acl = useAclStore()
const $alert = useAlertStore()
const $router = useRouter()
const $route = useRoute()
const realm = getRealm()

const handleSubmit = async () => {
  $alert.clear()

  if (!email.value) {
    $alert.setError('please enter email')
    return
  }
  loading.value = true
  try {
    realm.emailPasswordAuth.sendResetPasswordEmail({ email: email.value })
    sent.value = true
  }
  catch (e: any) {
    console.error('login failed', e)
    $alert.setError(e.message)
  }
  loading.value = false
}
</script>

<template>
  <div>
    <AForm layout="vertical" method="post" name="form3" @submit.prevent="handleSubmit">
      <div v-if="sent">
        <h2>Your request has been sent</h2>
        <p>
          Please follow instruction in your email to reset your password...
        </p>
      </div>
      <div v-else>
        <AFormItem label="Email">
          <AInput v-model:value="email" type="text"></AInput>
        </AFormItem>

        <AFormItem>
          <AButton type="primary" html-type="submit" class="login-button w-full" :loading="loading">Request new password</AButton>
        </AFormItem>
      </div>
    </AForm>
    <br />

    <div>
      <AButton type="default" class="w-full" @click="$router.push('/login')">Back to login</AButton>
    </div>

    <br />
  </div>
</template>

<style lang="scss"></style>
